import { Suspense } from 'react';
import dynamic from 'next/dynamic';

import { getStoriesBySlug } from '@helpers/posts';
import { mergeCardUsersData, limitUsersData, getUsers } from '@helpers/users';
import { getTalentTypeAndSmeVerticalPage } from '@cms/talentTypeAndSmeVertical';

import Header from '@components/Header/DefaultHeader';
import MainLayout from '@components/Layout/Layout';
import MainHero from '@components/PageComponents/Hero/MainHero/MainHero';
import TalentTypes from '@components/PageComponents/TalentTypes/TalentTypes';
import LogoSection from '@components/PageComponents/LogoSection/LogoSection';

const WhyWork = dynamic(() => import('@components/PageComponents/WhyWork/WhyWork'), {
  ssr: false,
});

const PrioritiesSection = dynamic(
  () => import('@components/PageComponents/PrioritiesSection/PrioritiesSection'),
  {
    ssr: false,
  }
);

const PostSection = dynamic(() => import('@components/PageComponents/PostSection/PostSection'), {
  ssr: false,
});

const GenericCta = dynamic(() => import('@components/PageComponents/CallToAction/GenericCta'), {
  ssr: false,
});
const CallToAction = dynamic(() => import('@components/PageComponents/CallToAction/CallToAction'), {
  ssr: false,
});
const Workflow = dynamic(() => import('@components/PageComponents/Workflow/CompanyWorkflow'), {
  ssr: false,
});
const PressSection = dynamic(() => import('@components/PageComponents/PressSection/PressSection'), {
  ssr: false,
});

const RightTriangle = dynamic(() => import('@components/Parallax/RightTriangle'));
const LeftTriangle = dynamic(() => import('@components/Parallax/LeftTriangle'));

const HomePage = ({
  head,
  heroTitle,
  heroDescription,
  ctaTitle,
  ctaDescription,
  ctaButtonLabel,
  ctaButtonLink,
  hero,
  footer,
  midCtaSection,
  clients,
  cardUsers,
  postData,
  directory,
  ...props
}) => {
  return (
    <MainLayout
      header={Header}
      footer={footer}
      pageHead={head}
      initialCanonical={process.env.NEXT_PUBLIC_CANONICAL_URL}>
      <MainHero
        customerNames={clients}
        users={cardUsers}
        title={heroTitle}
        description={heroDescription}
        talentCtaLinkTo={`/talent/register`}
        talentCtaLabel={`Apply As a Talent`}
        brandCtaLinkTo={`/get-started`}
        brandCtaLabel={`Work With Talent`}
      />
      <LogoSection logos={clients} />
      <RightTriangle />
      <TalentTypes />
      <LeftTriangle />
      <WhyWork />

      <GenericCta
        title={ctaTitle}
        description={ctaDescription}
        linkTo={ctaButtonLink}
        buttonLabel={ctaButtonLabel}
      />
      <RightTriangle />
      <Workflow />
      <PressSection />
      <PrioritiesSection data={directory} />
      <PostSection postData={postData} />
      <CallToAction />
    </MainLayout>
  );
};

export default HomePage;

export const getStaticProps = async (preview) => {
  let postData,
    apiUsers = [];

  const { homePage: page } = await getTalentTypeAndSmeVerticalPage(`/`, preview);

  if (!page) {
    return {
      notFound: true,
      revalidate: 7200,
      props: {
        error: {
          statusCode: 404,
          message: 'Page Not Found',
        },
      },
    };
  }
  // Get Users From Page
  if (page.users.length) {
    const users = page.users.map((t) => t.uuid);
    apiUsers = await getUsers(users);
  }

  if (page.articles) {
    postData = await getStoriesBySlug(page.articles);
  }

  const cardUsers = limitUsersData(
    mergeCardUsersData(
      [],
      page.users.map((e) => {
        return { uuid: e.uuid };
      }),
      apiUsers
    )
  );

  return {
    props: { ...page, postData, cardUsers },
    revalidate: 7200,
  };
};
