import Logo from '@hiredigital/ui/Logo';

import Link from '@components/Link';
import { DefaultLinks } from './DefaultLinks';
import MobileMenu from './MobileMenu';

import Styles from '../Header.module.scss';

const Default = ({ onMenuToggle, analyticsData, buttonLabel }) => {
  return (
    <>
      <Link href='/' className={Styles.logoLink} title={`Hire Digital`}>
        <Logo className={Styles.logo} site={'3'} />
      </Link>
      <MobileMenu onMenuToggle={onMenuToggle}>
        <DefaultLinks>
          <Link
            className={Styles.primaryButton}
            title=''
            href={{
              pathname: '/get-started',
              query: { ...analyticsData },
            }}>
            {buttonLabel || 'Work With Talent'}
          </Link>
        </DefaultLinks>
      </MobileMenu>
    </>
  );
};

export default Default;
